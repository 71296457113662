<template>
	<div>
		<b-card no-body class="mb-3">
			<base-table :headers="headers" :items="items" table-top paginate excel-file-name="community-assist"></base-table>
		</b-card>
	</div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';

export default {
	components: {
		BCard,
		BaseTable,
	},

	data: () => ({
		headers: [
			{ key: 'donor' },
			{ key: 'students', label: 'Students Enrolment' },
			{ key: 'housholds', label: 'Benf Housholds' },
			{ key: 'farmerAGR', label: 'Benf Farmares Agri' },
			{ key: 'roads', label: 'Benf People Roads' },
			{ key: 'sewerage', label: 'Benf People Sewerage' },
		],

		items: [],
	}),

	async created() {
		const { data } = await api.call({
			path: 'm&e/assists',
			method: 'GET',
		});

		this.items = data;
	},

	methods: {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
.table-responsive {
	margin-bottom: 0;
}
</style>
